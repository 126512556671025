import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderAlternative } from "../components/header-alternative"
import { HeaderTitle, HeaderSubtitle } from "../components/header"
import Container from "../components/container"
import { Box } from "../components/box"
import css from "@emotion/css"
import { Button } from "../components/button"
import { Label } from "../components/label"
import { Input } from "../components/input"

import "../components/forms.sass"
import { isValidEmail } from "../components/is-valid-email"

const ContactLabel = ({ children }) => (
  <Label
    css={css`
      width: 160px;
    `}
  >
    {children}
  </Label>
)

const InputGroup = ({ children }) => <div className="field">{children}</div>

const Textarea = props => <textarea className="textarea" {...props} />

const ContactPage = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [description, setDescription] = useState("")

  const hasRequiredFields = name && email && company && description
  return (
    <Layout>
      <SEO title="Contact us" />

      <HeaderAlternative>
        <HeaderTitle>Contact our team</HeaderTitle>
        <HeaderSubtitle>
          We are happy to answer all your questions. Fill out form and we'll be
          in touch soon.
        </HeaderSubtitle>
      </HeaderAlternative>

      <Container
        css={css`
          margin-top: -50px;
          justify-content: center;
        `}
      >
        <Box
          css={css`
            height: 420px;
          `}
        >
          <form
            name="contact"
            method="post"
            action="/contact-success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <InputGroup>
              <ContactLabel>Your full name</ContactLabel>
              <Input
                name="name"
                placeholder="Jon Snow"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <ContactLabel>Your email</ContactLabel>
              <Input
                name="email"
                placeholder="jon.snow@westeros.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <ContactLabel>Your Company</ContactLabel>
              <Input
                name="company"
                placeholder="Night's Watch, Inc."
                value={company}
                onChange={e => setCompany(e.target.value)}
              />
            </InputGroup>
            <InputGroup
              css={css`
                align-items: start;
              `}
            >
              <ContactLabel
                css={css`
                  padding-top: 6px;
                `}
              >
                Anything else?
              </ContactLabel>
              <Textarea
                name="description"
                placeholder="Tell us more about your project, needs, and timeline."
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </InputGroup>

            <div
              css={css`
            text-align: right;
          -`}
            >
              <Button
                disabled={!hasRequiredFields || !isValidEmail(email)}
                type="submit"
              >
                Contact our team
              </Button>
            </div>
          </form>
        </Box>
      </Container>
    </Layout>
  )
}

export default ContactPage
